import { ArrowPathIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import Retool from 'react-retool';

import MicroWrapper from './MicroWrapper';

interface RetoolContainerProps {
    appUUID: string;
    appName: string;
    data: object;
}

const RetoolContainer: React.FC<RetoolContainerProps> = ({
    appUUID,
    appName,
    data = {},
}) => {
    const [embedUrl, setEmbedUrl] = useState<string>('');

    const fetchEmbedUrl = async () => {
        setEmbedUrl('');
        const result = await fetch('/embed', {
            method: 'POST',
            body: JSON.stringify({
                appUUID,
            }),
        });
        if (result.ok) {
            const data = await result.json();
            if (!data.embedUrl) throw new Error('No embed URL returned from Retool API');
            setEmbedUrl(data.embedUrl);
        }
    };

    useEffect(() => {
        fetchEmbedUrl();
    }, [appUUID]);

    const headerName =
        appName.trim().length === 0 ? (
            ''
        ) : (
            <div className="px-4 py-5 sm:px-6">{appName}</div>
        );

    const headerContent = (
        <>
            {headerName}
            <button
                onClick={fetchEmbedUrl}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 rounded-full"
            >
                <ArrowPathIcon className="h-4 w-4 shrink-0" />
            </button>
        </>
    );

    const bodyContent = embedUrl ? (
        <Retool styling={{ height: '100%' }} url={embedUrl} data={data} />
    ) : (
        'Loading...'
    );

    return <MicroWrapper header={headerContent} body={bodyContent} />;
};

export default RetoolContainer;
