import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the grid
import './Worksheet.css';

import { useEffect } from 'react';

import { useError } from '../../context/ErrorContext';
import AuditGrid from '../components/AuditGrid';
import AuditSelect from '../components/AuditSelect';
import ExportXLSX from '../components/ExportXLSX';
import ImportCSV from '../components/ImportCSV';
import MicroWrapper from '../components/MicroWrapper';
import WorksheetProperties from '../components/WorksheetProperties';
import { selectedAudit } from '../state/audit';

function Worksheet() {
    const { handleError, clearError } = useError();

    const SESSION_CHECK_INTERVAL: number = 60000; // Check every 60 seconds
    const SESSION_CHECK_LOCK_NAME: string = 'session-check-lock';

    const performSessionCheck = async (retries = 3): Promise<void> => {
        try {
            const response: Response = await fetch('/api/auth/check2');
            if (!response.ok) {
                console.error(`Session expired or invalid - ${retries}`);
                handleError(
                    'Connection timed out.  Please refresh your screen and log in to dash to continue.1',
                );
                if (retries > 0) {
                    performSessionCheck(retries - 1);
                }
            } else {
                console.log('clearing error');
                clearError();
            }
            console.info('Session Check');
        } catch (error) {
            handleError(
                'Connection timed out.  Please refresh your screen and log in to dash to continue.2',
            );
            console.error('Error checking session:', error);
        }
    };

    const checkSession = async (): Promise<void> => {
        if (isLocksApiSupported()) {
            try {
                const result = await navigator.locks.request(
                    SESSION_CHECK_LOCK_NAME,
                    { ifAvailable: true }, // Only proceed if we can get the lock right away
                    async (lock) => {
                        if (!lock) {
                            console.debug(
                                'Session check already in progress in another tab',
                            );
                            return;
                        }

                        await performSessionCheck();
                        return true; // Signal that we performed the check
                    },
                );

                if (result !== true) {
                    return;
                }
            } catch (lockError) {
                console.error('Lock acquisition error:', lockError);
                await performSessionCheck();
            }
        } else {
            console.log('calling performSessionCheck');
            await performSessionCheck();
        }
    };

    const isLocksApiSupported = (): boolean => {
        return 'locks' in navigator && typeof navigator.locks?.request === 'function';
    };

    useEffect(() => {
        checkSession();
        const intervalId = setInterval(checkSession, SESSION_CHECK_INTERVAL);

        return () => clearInterval(intervalId);
    }, []);

    const headerContent = (
        <div className="px-4 py-5 flex w-full gap-4">
            <AuditSelect selectedAudit={selectedAudit}></AuditSelect>
            {selectedAudit && (
                <p className="p-2 text-sm text-gray-600">
                    Board ID: {selectedAudit.value?.id}
                </p>
            )}
            <div className="justify-end grow flex">
                <ExportXLSX
                    selectedAudit={selectedAudit.value}
                    filename={selectedAudit.value?.title || ''}
                ></ExportXLSX>
                <ImportCSV selectedAudit={selectedAudit.value}></ImportCSV>
                <WorksheetProperties selectedAudit={selectedAudit.value} />
            </div>
        </div>
    );

    const bodyContent = <AuditGrid audit={selectedAudit.value}></AuditGrid>;

    return <MicroWrapper header={headerContent} body={bodyContent} />;
}

export default Worksheet;
