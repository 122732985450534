import RetoolContainer from '../components/RetoolContainer';
import { useModal } from '../providers/Modal';
import { TransformedAuditBoard } from '../Types/Audit';

interface AuditProps {
    selectedAudit?: TransformedAuditBoard;
}

const WorksheetProperties: React.FC<AuditProps> = ({ selectedAudit }) => {
    const { showModal } = useModal();

    return (
        <>
            <button
                className="ml-2 rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() =>
                    showModal(
                        <div>
                            <RetoolContainer
                                appName=""
                                appUUID="c757d2f0-f946-11ef-aa23-1b0f3dc58570"
                                data={{ worksheet_id: selectedAudit?.id }}
                            ></RetoolContainer>
                        </div>,
                        '',
                        'w-3/4',
                    )
                }
            >
                Worksheet Properties
            </button>
        </>
    );
};

export default WorksheetProperties;
