// src/components/ErrorDisplay.js
import React from 'react';

import { useError } from '../../context/ErrorContext';

const ErrorDisplay = () => {
    const { error, clearError } = useError();

    if (!error) return null;

    return (
        <div className="bg-red-200 text-red-500 text-center p-3">
            <strong>Error: {error}</strong>
            <button onClick={clearError} className="float-end">
                X
            </button>
        </div>
    );
};

export default ErrorDisplay;
