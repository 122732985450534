// src/components/ErrorBoundary.js
import React, { Component } from 'react';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
class ErrorBoundary extends Component<any> {
    state = { hasError: false, errorMessage: '' };

    static getDerivedStateFromError(error: { message: string }) {
        return { hasError: true, errorMessage: error.message };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentDidCatch(error: any, info: any) {
        // Here you can log the error to an external service, if needed
        console.error('Caught error:', error, info);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="bg-red-200 text-red-500 text-center p-3">
                    <strong>Error: {this.state.errorMessage}</strong>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
