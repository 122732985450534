import { useQueryClient } from '@tanstack/react-query';
import Papa from 'papaparse';
import React, { useRef, useState } from 'react';

import { useModal } from '../providers/Modal';
import { TransformedAuditBoard } from '../Types/Audit';

interface ImportCSVProps {
    selectedAudit?: TransformedAuditBoard;
}

const ImportCSV: React.FC<ImportCSVProps> = ({ selectedAudit }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<string[]>([]);
    const { showModal } = useModal();

    const handleFileSelect = () => {
        inputRef?.current?.click();
    };

    const queryClient = useQueryClient();
    const clearCardsCache = () => {
        const auditId = selectedAudit?.id;
        queryClient.invalidateQueries({ queryKey: ['audits', 'cards', { auditId }] });
        queryClient.invalidateQueries({ queryKey: ['audits', 'reviews', { auditId }] });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleFileUpload = async (event: any) => {
        const file = event.target.files[0];
        const rows: string[] = (await parseCSV(file)) as string[];
        const [isValid, missingCols] = validateImport(rows);
        if (file && isValid) {
            const formData = new FormData();
            formData.append('file', file);

            const response = await fetch(
                `api/audits/${selectedAudit?.id}/update_cards_by_ref_id`,
                {
                    method: 'POST',
                    body: formData,
                },
            );

            let message = null;
            const body = JSON.parse(await response.text());
            if (response.ok) {
                const summary = JSON.parse(body.message);
                message = (
                    <div>
                        <p>Cards updated: {summary.updated.length}.</p>
                        <p>Cards created: {summary.created.length}.</p>
                        <p>Failed rows: {summary.failed.length}.</p>
                        <p>
                            <span className="text-red-900 pr-2 font-semibold">
                                {summary.errors.length > 0 ? 'Errors:' : ''}
                            </span>
                            {summary.errors.join(',')}
                        </p>
                    </div>
                );
            } else {
                message = (
                    <span className="text-red-900 pr-2 font-semibold">
                        {body.message}
                    </span>
                );
            }
            displayModal('Import CSV', message);
            clearCardsCache();
        } else {
            const mCols = Array.isArray(missingCols)
                ? missingCols.join(', ')
                : missingCols.toString();
            setError([`Missing Columns`, mCols]);
            console.log('Error', missingCols);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const displayModal = (title: string | null, message: any, error = false) => {
        showModal(
            <div className="text-gray-600">
                <p
                    className={`${error ? 'text-red-700' : 'text-gray-900'} font-semibold`}
                >
                    {message}
                </p>
            </div>,
            title || '',
        );
    };

    const parseCSV = (file: File) => {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const resultData: any[] = [];

            Papa.parse(file, {
                step: function (results, _parser) {
                    if (Array.isArray(results.data)) resultData.push(results.data);
                },
                complete: function () {
                    resolve(resultData);
                },
                error: function (error) {
                    reject(error);
                },
            });
        });
    };

    const validateImport = (rows: string[]) => {
        const first = rows[0];
        const missingCols = Object.values(columnsMap).filter((v) => !first.includes(v));
        return [missingCols.length == 0, missingCols];
    };

    const columnsMap = {
        section: 'Section',
        title: 'Title',
        refId: 'Ref ID',
        controlDescription: 'Control Description',
        auditProcedure: 'Service Auditor Tests',
        frequency: 'Frequency',
        importance: 'Importance',
        uwvComments: 'Comments',
        status: 'Board Status',
        worksheetStatus: 'Testing Status',
        testingComments: 'Test Results',
        results: 'Results',
        supervisorReview: 'Senior Review',
        managerReview: 'Manager Review',
        supervisorReviewStatus: 'Senior Review Status',
        managerReviewStatus: 'Manager Review Status',
    };

    if (error.length > 0) {
        displayModal(error[0], error[1], true);
        setError([]);
    }

    return (
        <>
            <input
                type="file"
                id="importCSV"
                className="hidden"
                ref={inputRef}
                onChange={handleFileUpload}
            />
            <button
                onClick={handleFileSelect}
                className="ml-2 rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                Import CSV
            </button>
        </>
    );
};

export default ImportCSV;
