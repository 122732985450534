// src/context/ErrorContext.js
import React, { createContext, useContext, useState } from 'react';

interface ErrorContextType {
    error: string | null;
    handleError: (message: string) => void;
    clearError: () => void;
}

// Create the context
const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

// Provider to manage the error state
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ErrorProvider = ({ children }: { children: any }) => {
    const [error, setError] = useState<null | string>(null);

    const handleError = (message: string) => {
        setError(message);
    };

    const clearError = () => {
        setError(null);
    };

    return (
        <ErrorContext.Provider value={{ error, handleError, clearError }}>
            {children}
        </ErrorContext.Provider>
    );
};

// Custom hook to use the error context
export const useError = (): ErrorContextType => {
    const context = useContext(ErrorContext);
    if (!context) {
        throw new Error('useError must be used within an ErrorProvider');
    }
    return context;
};
